import React, { useEffect } from "react";
import '../assets/css/main.css';
import mainimage from '../assets/img/hero-bg.jpg';
import AOS from "aos";
import "aos/dist/aos.css";
import TypingAnimator from "react-typing-animator";

function Hero() {

    const textArray = [
        'Technology Leader...',
        'Software Engineer...',
        'Team Builder...',
        'Mentor...',
        'Project Manager...',
        'How can I help your team today?'];

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <section id="hero" className="hero">
            <img src={mainimage} alt="" data-aos="fade-in" />
            <div className="container" data-aos="fade-up" data-aos-delay="100" style={{backgroundColor: 'rgba(255, 255, 255, 0.75)', height: '130px', lineHeight: '130px'}}>
                <span style={{display: 'inline-block', verticalAlign: 'middle', lineHeight: '35px'}}>
                    <h2 style={{color: "#151f2b"}}>Jose Bravo-Tejeda</h2>
                    <p style={{color: "#151f2b"}}><TypingAnimator textArray={textArray}
                                    cursorColor="#151f2b"
                                    textColor="#151f2b"
                                    fontSize="24px"
                                    typingSpeed={50}
                                    delaySpeed={1500}
                                    height={'200px'}
                                    backspace={true}
                                    loop={true}/>
                    </p>
                </span>
            </div>
        </section>
    );
}

export default Hero;